import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["card", "text"];
  static values = { text: String };

  connect() {
    this._boundUpdateText = this.updateText.bind(this);
    document.addEventListener("promote:tooltip-update-text", this._boundUpdateText);
  }

  disconnect() {
    document.removeEventListener("promote:tooltip-update-text", this._boundUpdateText);
  }

  toggle() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.toggle("hidden");
    }
  }

  show() {
    if (this.hasCardTarget && this.cardTarget.classList.contains("hidden") && this._hasContent()) {
      this.cardTarget.classList.remove("hidden");
    }
  }

  hide() {
    if (this.hasCardTarget && !this.cardTarget.classList.contains("hidden")) {
      this.cardTarget.classList.add("hidden");
    }
  }

  updateText(event) {
    if (this.hasCardTarget && this.hasTextTarget) {
      this.textTarget.innerText = event.detail.text;
    }
  }

  _hasContent() {
    return this.hasTextTarget && this.textTarget.innerText.trim().length > 0;
  }
}
