import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";

export default class extends Controller {
  static targets = ["form", "submitButton", "fileInput", "titleInput"];

  static values = { errorMessage: String };

  connect() {
    this.boundForm = this.handleFormSubmit.bind(this);
    this.formTarget.addEventListener("submit", this.boundForm);
  }

  disconnect() {
    this.formTarget.removeEventListener("submit", this.boundForm);
  }

  async handleFormSubmit(event) {
    event.preventDefault();

    this.methodOverride = this.formTarget.querySelector('input[name="_method"]')?.value || this.formTarget.method;

    // if no file is selected, but the title is present, submit the form
    if (!this.fileInputTarget.files[0] && this.titleInputTarget.value != "" &&
        this.methodOverride == "patch") {
      this._disableSubmitButton();

      await this._submitUpdate();

      return;
    }

    this._handleFileChange();
  }

  async _handleFileChange() {
    const file = this.fileInputTarget.files[0];
    if (!file) return;

    this._disableSubmitButton();

    try {
      const authToken = await this._getAuthToken();
      const formData = new FormData();
      formData.append("file", file);

      // get jobId from upload
      const uploadResponse = await this._uploadFile(authToken, formData);

      const jobId = uploadResponse.result;

      if (!jobId) return;

      await this._submitUpdate(jobId);
    } catch (error) {
      document.dispatchEvent(new CustomEvent('promote:display-error-message', {
        detail: {
         message: this.errorMessageValue
        }
      }));
    }
  }

  async _getAuthToken() {
    return new Promise((resolve, reject) => {
      ajax({
        url: "/admin/scorm_cloud_courses/auth_token",
        type: "GET",
        dataType: "json",
        success: (data) => {
          resolve(data.result);
        },
        error: (xhr, status, error) => {
          reject(error);
        }
      });
    });
  }

  async _uploadFile(authToken, formData) {
    return new Promise((resolve, reject) => {
      var req = new XMLHttpRequest();
      req.open("POST", this.fileInputTarget.dataset.url);
      req.setRequestHeader("Authorization", `Bearer ${authToken}`);

      req.onreadystatechange = function () {
        if (req.readyState === XMLHttpRequest.DONE) {
          if (req.status === 200) {
            resolve(JSON.parse(req.response));
          } else {
            reject(req.responseText);
          }
        }
      };

      req.send(formData);
    });
  }

  async _submitUpdate(jobId = null) {
    const formData = new FormData(this.formTarget);

    if (jobId != null) {
      formData.append("jobid", jobId);
    }

    return new Promise((resolve, reject) => {
      ajax({
        url: this.formTarget.action,
        type: this.methodOverride,
        data: formData,
        processData: false,
        contentType: false,
        dataType: 'script',
        success: (data) => resolve(data),
        error: (xhr, status, error) => reject(error),
      });
    });
  }

  _disableSubmitButton() {
    this.submitButtonTarget.disabled = true;
  }
}
